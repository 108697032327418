import React from "react";
import { graphql } from "gatsby";

import { QueryFragments } from "@querys/queryFragments"; // eslint-disable-line

// Components
import { PageMeta } from "@components/pageMeta";
import ModuleZone from "~moduleZone";
import PageBanner from "@components/pageModules/modules/pageBanner";
import Layout from "@components/layout";
import Form from "../components/contact/form";

const ContactTemplate = ({ data: { page } }) => {
  const { seo, banner, pageModules } = page || {};

  return (
    <Layout>
      <PageMeta {...seo} />
      <PageBanner {...banner} />
      {pageModules && <ModuleZone {...pageModules} />}
      {/* removed until futher notice */}
      {/* <Form /> */}
    </Layout>
  );
};

export default ContactTemplate;

export const contactQuery = graphql`
  query contactQuery($slug: String!) {
    page: sanityContact(slug: { current: { eq: $slug } }) {
      seo {
        ...SEO
      }
      title
      banner {
        ...PageBanner
      }
      pageModules: modules {
        ...PageModules
      }
    }
  }
`;
